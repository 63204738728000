<template>
    <div class="h-full">
        <PaintingDetails :paintingId="id" />
        <button class="flex items-center px-8" @click="goBack">
            <SvgRenderer icon="arrow">
                <ArrowBack />
            </SvgRenderer>
            <span class="ml-2">Indietro</span>
        </button>
    </div>
</template>
<script lang="ts">
import { mapGetters } from 'vuex'
import { useRoute } from 'vue-router'
import { Options, Vue } from 'vue-class-component'
import ArrowBack from '../assets/svg/arrow-back.svg'
import PaintingDetails from '@/components/PaintingDetails.vue'
import SvgRenderer from '@/components/SvgRenderer.vue'

@Options({
    name: 'Painting',
    computed: {
        ...mapGetters({
            allPaintings: 'getAllPaintings'
        }),
        id() {
            const router = useRoute()
            return router.params.id
        }
    },
    components: {
        ArrowBack,
        PaintingDetails,
        SvgRenderer
    },
    methods: {
        goBack() {
            return this.$router.go(-1)
        }
    }
})
export default class Painting extends Vue {}
</script>
<style lang="scss" scoped>
button {
    @include responsive(md) {
        height: 10%;
    }
}
</style>
