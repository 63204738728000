
import { Options, Vue } from 'vue-class-component'

@Options({
    name: 'ArtGalleryEvent',
    computed: {
        futureMemoryImg() {
            return `${process.env.VUE_APP_IMG_URL}/la-memoria-del-futuro.jpg`
        },
        eventoBrochure() {
            return `${process.env.VUE_APP_IMG_URL}/brochure-villasanta.jpg`
        },
        pieghevoleEvento() {
            return `${process.env.VUE_APP_IMG_URL}/pieghevole-villasanta-1.jpg`
        },
        pieghevoleEvento2() {
            return `${process.env.VUE_APP_IMG_URL}/pieghevole-villasanta-2.jpg`
        },
        iconArrowDown() {
            return `${process.env.VUE_APP_IMG_URL}/arrow-down-icon.svg`
        }
    }
})
export default class ArtGalleryEvent extends Vue {}
