<template>
    <div class="about">
        <div class="container">
            <div class="content">
                <div class="mb-4">
                    <h2 class="text-4xl mt-2 mb-2 highlighted font-bold">
                        ABOUT ME
                    </h2>

                    <p>
                        Infrangendo la regola di non pubblicare cose personali,
                        racconterò solo queste
                    </p>
                    <br />

                    <p>
                        <strong>Soprannome:</strong> Gechi, ereditato da bambino
                        che amava i films western. Ma scritto all'italiana.
                    </p>
                    <p><strong>Segno zodiacale:</strong> gemelli.</p>
                    <p>
                        <strong>Altezza verticale:</strong> 1,85 cm, molto meno
                        da sdraiato.
                    </p>
                    <p>
                        <strong>Musica preferita:</strong> quella che passa
                        sulla radio....., oltre il canto dei merli all'alb....
                        Odio i galli.
                    </p>
                    <p><strong>Numero fortunato:</strong> non averne uno.</p>
                    <p>
                        <strong>Cibo preferito:</strong> cocomeri, purchè
                        grandi.
                    </p>
                    <p>
                        <strong>Lavoro da sogno:</strong> giardinaggio estremo
                        in un giardino di cactus fioriti.
                    </p>
                    <p>
                        <strong>Strumenti:</strong> il silenzio per le cose
                        importanti, il temporale per capirlo meglio.
                    </p>
                    <p>
                        <strong>Il mio look preferito:</strong> indossare dei
                        sorrisi, gli altri abiti sono noiosi.
                    </p>
                    <p>
                        <strong>Il giorno ideale:</strong> oscilla tra il sole e
                        la luna. Non importa se fuori piove
                    </p>
                    <p>
                        <strong>Consumatore di:</strong> articoli di
                        cancelleria.
                    </p>
                    <p>
                        <strong>Mezzo di trasporto:</strong> pedibus
                        calcantibus.
                    </p>
                    <p>
                        <strong>Nella valigia per i viaggi:</strong> il pensiero
                        viaggiatore.
                    </p>
                    <p>
                        <strong>Aspirazione:</strong> apoteosi dell'artigianato
                        manuale, il lavoro preciso che fa le cose per bene
                        (Primo Levi - La chiave a stella).
                    </p>
                    <p>
                        <strong>Artisticamente:</strong> senza partecipazioni
                        significative.
                    </p>
                </div>
                <br />
                <div class="mb-4">
                    <h2 class="text-4xl mt-2 mb-4 highlighted font-bold">
                        LE RAGIONI DEL FARE
                    </h2>
                    <p class="pr-8">
                        Paesaggio come soggetto privilegiato del rapporto
                        tempo-memoria: è un percorso pittorico iniziato alcuni
                        anni fa quando unisce al suo lavoro di architetto quello
                        di viaggiatore a ritroso nella memoria sedimentata dei
                        luoghi che più hanno lasciato un segno profondo nel
                        ricordo. <br />
                        Un percorso estremamente soggettivo e personale per dare
                        spazio ad una passione coltivata da sempre, alla ricerca
                        di quanto i luoghi, gli orizzonti, i panorami si
                        sedimentino nel profondo.
                        <br />
                        Una pittura che non concede nulla ad una interpretazione
                        "romantica" del paesaggismo turistico, che ci abitua ad
                        una bellezza spesso finta, che si fa invece visione ed
                        atmosfera cercando di esprimere l'essenza dei paesaggi
                        incontrati nei vagabondaggi mediterranei e nei luoghi
                        dell'infanzia.
                        <br />
                        Per essere, per esserci, dobbiamo avere molti luoghi
                        dentro di noi, luoghi che ci raccontano cosa abbiamo
                        creato, luoghi che ci dicano cosa siamo e cosa siamo
                        stati, luoghi che ci legano per nostalgia, per
                        desiderio, per malinconia, per amore o rancore. Paesaggi
                        interiori che riaffiorano di tanto in tanto, paesaggi da
                        coltivare per sognare e immaginare al di là di noi
                        stessi. Visioni tradotte in segno, luce e colore. <br />
                        Rappresentazione, quindi, non di una riproduzione della
                        realtà, ma il risultato di una esperienza, di una
                        rievocazione, di una mediazione, di uno stato d'animo,
                        di un lavoro di conservazione. Allora troviamo le
                        campagne ed i paesi dell'Italia, il costruito spontaneo
                        dei paesi mediterranei, in particolare delle isole, la
                        loro armonia, frutto di misura e necessità, le luci dei
                        borghi di notte con le rogge che li attraversano, e
                        troviamo gli alberi, esseri grandi ed animati, uniche
                        presenze cui il paesaggio attribuisce la capacità di
                        esprimere tutte le necessità dell'essere. Alberi assunti
                        come metafore della socialità o della solitudine
                        dell'uomo di fronte alla natura o alla presenza
                        dell'elemento naturale nel costruito o l'albero che ci
                        vuole parlare della natura. E poi fiumi, rogge e canali,
                        colline, montagne, sentieri, paesi, case, con il loro
                        corollario di cose, che con il loro essere il nostro
                        universo ci abitano e diventano luoghi del ritorno.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { mapGetters, useStore } from 'vuex'
@Options({
    name: 'About',
    computed: {
        ...mapGetters(['isMobileMenuOpen'])
    }
})
export default class About extends Vue {
    store = useStore()
    isMobileMenuOpen!: boolean
    mounted() {
        if (this.isMobileMenuOpen) {
            this.store.dispatch('toggleMenuMobile')
        }
    }
}
</script>
<style lang="scss" scoped>
.about {
    align-items: center;
    display: flex;
    justify-content: center;

    .container {
        max-width: 100rem;
    }
}
</style>
