
import { mapGetters } from 'vuex'
import { useRoute } from 'vue-router'
import { Options, Vue } from 'vue-class-component'
import ArrowBack from '../assets/svg/arrow-back.svg'
import PaintingDetails from '@/components/PaintingDetails.vue'
import SvgRenderer from '@/components/SvgRenderer.vue'

@Options({
    name: 'Painting',
    computed: {
        ...mapGetters({
            allPaintings: 'getAllPaintings'
        }),
        id() {
            const router = useRoute()
            return router.params.id
        }
    },
    components: {
        ArrowBack,
        PaintingDetails,
        SvgRenderer
    },
    methods: {
        goBack() {
            return this.$router.go(-1)
        }
    }
})
export default class Painting extends Vue {}
