
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Options, Vue } from 'vue-class-component'
import Close from '../assets/svg/close.svg'
import PaintingImage from './PaintingImage.vue'
import SvgRenderer from './SvgRenderer.vue'

class Props {
    img!: object
}

@Options({
    name: 'ImageModal',
    props: {
        img: Object
    },
    components: {
        Close,
        PaintingImage,
        SvgRenderer
    },
    computed: {
        category() {
            return this.img.category
        },
        id() {
            return this.img.id
        },
        title() {
            return this.img.title
        }
    }
})
export default class ImageModal extends Vue.with(Props) {
    closeModal() {
        this.$emit('closeModal')
    }
}
