<template>
    <div class="card">
        <v-lazy-image
            class="m-auto"
            :src="getImageUrl(paintingId, category)"
            :alt="title"
            @contextmenu="preventRightClick"
        />
    </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import VLazyImage from 'v-lazy-image'

@Options({
    name: 'PaintingImage',
    props: {
        paintingId: String,
        category: String,
        title: String
    },
    components: {
        VLazyImage
    }
})
export default class PaintingImage extends Vue {
    getImageUrl(id: string, category: string) {
        const formattedCategory = category
            .replace(/ +/g, '-')
            .replace(/acrilico/, 'acrilici')

        return `${process.env.VUE_APP_IMG_URL}/${formattedCategory}/${id}.webp`
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    preventRightClick(evt: any) {
        evt.preventDefault()
    }
}
</script>
<style lang="scss" scoped></style>
