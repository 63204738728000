
import { Vue, Options } from 'vue-class-component'

@Options({
    name: 'LinkToCategory',
    props: {
        category: String
    }
})
export default class LinkToCategory extends Vue {
    capitalizeString(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
    addDashCategory(string: string) {
        return string.toLowerCase().replace(/\s+/g, '-')
    }
}
