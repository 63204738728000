<template>
    <div class="my-2 flex md:items-center mx-auto max-w-screen-lg">
        <div class="img-container">
            <img :src="eventoBrochure" />
        </div>
    </div>
    <div class="my-6 mx-auto max-w-screen-lg event-details">
        <h2 class="flex items-baseline justify-center header-title my-4">
            Dettagli sulla collezione
            <div class="arrow-container ml-4">
                <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill="#013220"
                >
                    <path
                        d="M24 12c0-6.623-5.377-12-12-12s-12 5.377-12 12 5.377 12 12 12 12-5.377 12-12zm-1 0c0-6.071-4.929-11-11-11s-11 4.929-11 11 4.929 11 11 11 11-4.929 11-11zm-11.5 4.828l-3.763-4.608-.737.679 5 6.101 5-6.112-.753-.666-3.747 4.604v-11.826h-1v11.828z"
                    />
                </svg>
            </div>
        </h2>
        <div class="brochure-container">
            <div>
                <img :src="pieghevoleEvento" />
            </div>
            <div class="mt-8">
                <img :src="pieghevoleEvento2" />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
    name: 'ArtGalleryEvent',
    computed: {
        futureMemoryImg() {
            return `${process.env.VUE_APP_IMG_URL}/la-memoria-del-futuro.jpg`
        },
        eventoBrochure() {
            return `${process.env.VUE_APP_IMG_URL}/brochure-villasanta.jpg`
        },
        pieghevoleEvento() {
            return `${process.env.VUE_APP_IMG_URL}/pieghevole-villasanta-1.jpg`
        },
        pieghevoleEvento2() {
            return `${process.env.VUE_APP_IMG_URL}/pieghevole-villasanta-2.jpg`
        },
        iconArrowDown() {
            return `${process.env.VUE_APP_IMG_URL}/arrow-down-icon.svg`
        }
    }
})
export default class ArtGalleryEvent extends Vue {}
</script>

<style lang="scss" scoped>
.details {
    @include responsive(md) {
        width: calc(100% - 450px);
    }
}

.header-title {
    font-size: 3rem;
}

.arrow-container {
    width: 18px;
    height: 18px;
}

.img-container {
    max-width: 250px;
    @include responsive(md) {
        max-width: 400px;
    }
    margin: 0 auto;
}

.brochure-container {
    width: 80%;
    margin: 0 auto;
}
</style>
