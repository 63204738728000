
import { Vue, Options } from 'vue-class-component'
import { mapGetters, useStore } from 'vuex'
import ContactForm from '@/components/ContactForm.vue'
import NotificationFlag from '@/components/NotificationFlag.vue'
import SvgRenderer from '@/components/SvgRenderer.vue'
import Phone from '@/assets/svg/phone.svg'

@Options({
    name: 'Contacts',
    computed: {
        ...mapGetters(['isMobileMenuOpen'])
    },
    components: {
        ContactForm,
        NotificationFlag,
        Phone,
        SvgRenderer
    }
})
export default class Contacts extends Vue {
    store = useStore()
    submissionType = ''
    isMobileMenuOpen!: boolean
    mounted() {
        if (this.isMobileMenuOpen) {
            this.store.dispatch('toggleMenuMobile')
        }
    }
    setSubmissionType(value: string) {
        this.submissionType = value
    }
}
