<template>
    <router-link
        class="link"
        :to="{
            name: 'Gallery',
            params: { category: addDashCategory(category) }
        }"
    >
        <span>
            {{ capitalizeString(category) }}
        </span>
    </router-link>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'

@Options({
    name: 'LinkToCategory',
    props: {
        category: String
    }
})
export default class LinkToCategory extends Vue {
    capitalizeString(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
    addDashCategory(string: string) {
        return string.toLowerCase().replace(/\s+/g, '-')
    }
}
</script>
