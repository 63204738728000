
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { mapGetters } from 'vuex'
import { Options, Vue } from 'vue-class-component'
import { useGtag } from 'vue-gtag-next'
import ImageModal from './ImageModal.vue'
import PaintingImage from './PaintingImage.vue'
import PaintingDescription from './PaintingDescription.vue'
import SliderController from './SliderController.vue'

class Props {
    paintingId!: string
}

interface Image {
    url: string
}

interface Painting {
    id: string
    title: string
    size: string
    image: Image
    description: string
    category: string
}

@Options({
    name: 'PaintingsSlider',
    props: {
        paintingId: String
    },
    components: {
        ImageModal,
        PaintingImage,
        PaintingDescription,
        SliderController
    },
    computed: {
        ...mapGetters({
            filteredPaintings: 'getFilteredArray'
        })
    }
})
export default class PaintingsSlider extends Vue.with(Props) {
    indexPainting = 0
    back = false
    isModalOn = false
    imgModal: object = {}
    painting: Painting = {
        id: '0',
        title: '',
        description: '',
        size: '',
        image: {
            url: ''
        },
        category: ''
    }
    filteredPaintings!: []
    mounted() {
        this.painting = this.filterPainting(this.filteredPaintings)

        this.indexPainting = this.filteredPaintings.findIndex(
            (painting: Painting) => painting.id === this.paintingId
        )
    }
    updatePaintingObject({ direction, newIndex }) {
        this.back = direction === 'back'
        this.painting = this.filteredPaintings[newIndex]
        this.indexPainting = newIndex
        this.$router.replace({
            path: `/painting/${this.painting.id}`
        })
        this.trackEvent(
            'Galleria Quadri',
            'Opera selezionata',
            this.painting.title
        )
    }
    trackEvent(evt, category, label) {
        const { event } = useGtag()
        const track = () => {
            event(evt, {
                eventCategory: category,
                eventLabel: label
            })
        }

        return {
            track
        }
    }
    filterPainting(array: any) {
        return array.filter(({ id }: any) => id === this.paintingId)[0]
    }
    closeModal() {
        this.isModalOn = false
    }
    openImageModal(painting: object) {
        this.imgModal = painting
        this.isModalOn = true
    }
}
