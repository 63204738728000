<template>
    <div
        class="relative md:inline-block h-2/4 md:h-full md:mx-8 md:align-middle md:w-3/5"
    >
        <div class="md:flex items-center h-full">
            <div class="painting relative my-0 mx-auto md:w-9/12 h-full">
                <transition-group
                    tag="div"
                    :name="back ? 'slide-fade-back' : 'slide-fade-next'"
                    class="slider"
                >
                    <template
                        v-for="(painting, index) in filteredPaintings"
                        :key="`img-${index}`"
                    >
                        <PaintingImage
                            v-show="index === indexPainting"
                            @click="openImageModal(painting)"
                            :paintingId="painting.id"
                            :category="painting.category"
                            :title="painting.title"
                        />
                    </template>
                </transition-group>
            </div>
            <SliderController
                :index="indexPainting"
                @show-next-slide="updatePaintingObject"
                @show-prev-slide="updatePaintingObject"
            />
        </div>
    </div>
    <div
        class="md:inline-block align-middle md:w-1/3 h-2/4 md:h-full mt-32 md:m-6 md:mt-0 text-center md:text-left"
    >
        <transition-group tag="div" name="fade-gallery" class="slider">
            <PaintingDescription
                v-for="(painting, dIndex) in filteredPaintings"
                :key="`details-${dIndex}`"
                v-show="dIndex === indexPainting"
                :title="painting.title"
                :description="painting.description"
                :category="painting.category"
                :size="painting.size"
            />
        </transition-group>
    </div>
    <ImageModal
        v-if="isModalOn && imgModal"
        :img="imgModal"
        @close-modal="closeModal"
    />
</template>
<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { mapGetters } from 'vuex'
import { Options, Vue } from 'vue-class-component'
import { useGtag } from 'vue-gtag-next'
import ImageModal from './ImageModal.vue'
import PaintingImage from './PaintingImage.vue'
import PaintingDescription from './PaintingDescription.vue'
import SliderController from './SliderController.vue'

class Props {
    paintingId!: string
}

interface Image {
    url: string
}

interface Painting {
    id: string
    title: string
    size: string
    image: Image
    description: string
    category: string
}

@Options({
    name: 'PaintingsSlider',
    props: {
        paintingId: String
    },
    components: {
        ImageModal,
        PaintingImage,
        PaintingDescription,
        SliderController
    },
    computed: {
        ...mapGetters({
            filteredPaintings: 'getFilteredArray'
        })
    }
})
export default class PaintingsSlider extends Vue.with(Props) {
    indexPainting = 0
    back = false
    isModalOn = false
    imgModal: object = {}
    painting: Painting = {
        id: '0',
        title: '',
        description: '',
        size: '',
        image: {
            url: ''
        },
        category: ''
    }
    filteredPaintings!: []
    mounted() {
        this.painting = this.filterPainting(this.filteredPaintings)

        this.indexPainting = this.filteredPaintings.findIndex(
            (painting: Painting) => painting.id === this.paintingId
        )
    }
    updatePaintingObject({ direction, newIndex }) {
        this.back = direction === 'back'
        this.painting = this.filteredPaintings[newIndex]
        this.indexPainting = newIndex
        this.$router.replace({
            path: `/painting/${this.painting.id}`
        })
        this.trackEvent(
            'Galleria Quadri',
            'Opera selezionata',
            this.painting.title
        )
    }
    trackEvent(evt, category, label) {
        const { event } = useGtag()
        const track = () => {
            event(evt, {
                eventCategory: category,
                eventLabel: label
            })
        }

        return {
            track
        }
    }
    filterPainting(array: any) {
        return array.filter(({ id }: any) => id === this.paintingId)[0]
    }
    closeModal() {
        this.isModalOn = false
    }
    openImageModal(painting: object) {
        this.imgModal = painting
        this.isModalOn = true
    }
}
</script>
<style lang="scss" scoped>
.slider {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.painting {
    cursor: zoom-in;
    z-index: 30;
}

.card {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    img {
        cursor: zoom-in;
    }
}
</style>
