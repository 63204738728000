
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Options, Vue } from 'vue-class-component'
import { mapGetters, useStore } from 'vuex'
import { useGtag } from 'vue-gtag-next'
import Expand from '../assets/svg/expand.svg'
import SvgRenderer from '@/components/SvgRenderer.vue'
import PaintingImage from '@/components/PaintingImage.vue'
import SideMenu from '@/components/SideMenu.vue'

@Options({
    name: 'Gallery',
    components: {
        Expand,
        PaintingImage,
        SideMenu,
        SvgRenderer
    },
    computed: {
        ...mapGetters({
            allPaintings: 'getAllPaintings',
            categoriesName: 'getAllCategoriesName',
            isMobileMenuOpen: 'isMobileMenuOpen',
            filteredPaintings: 'getFilteredArray'
        })
    },
    watch: {
        '$route.params.category': {
            handler: function(category, categoryOld) {
                if (category && categoryOld !== category) {
                    this.setArray(category)
                    this.trackCategory(category)
                    if (this.isMobileMenuOpen) {
                        this.store.dispatch('toggleMenuMobile')
                    }
                }
            },
            deep: true,
            immediate: true
        },
        filteredPaintings(newArray, oldArray) {
            if (newArray !== oldArray) {
                this.chunkedArray = this.createGroups(newArray, this.numChunks)
            }
        }
    }
})
export default class Gallery extends Vue {
    allPaintings!: []
    isMobileMenuOpen!: boolean
    store = useStore()
    selectedCategory = ''
    categoriesName!: Array<string>
    filteredPaintings!: []
    numChunks = 3
    chunkedArray: Array<Array<object>> = []
    async created() {
        await this.store.dispatch('setPaintingsArray')
        this.chunkedArray = this.createGroups(
            this.filteredPaintings,
            this.numChunks
        )
    }
    mounted() {
        this.setArray(this.$route.params.category)
        if (this.isMobileMenuOpen) {
            this.store.dispatch('toggleMenuMobile')
        }
    }
    setArray(category: any) {
        this.selectedCategory = this.removeDashCategory(category)
        const filteredArray =
            this.selectedCategory === 'all'
                ? this.allPaintings
                : this.store.getters.getPaintingsByCategory(
                      this.selectedCategory
                  )
        this.store.dispatch('setFilteredArray', filteredArray)
    }
    removeDashCategory(string: string) {
        return string.replace(/-/g, ' ')
    }
    capitalise(string: string) {
        return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
    }
    createGroups(arr, numGroups) {
        const perGroup = Math.ceil(arr.length / numGroups)
        return new Array(numGroups)
            .fill('')
            .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup))
    }
    trackCategory(category) {
        const { event } = useGtag()
        const track = () => {
            event('Pagina iniziale', {
                eventCategory: 'Categoria Selezionata',
                eventLabel: category
            })
        }

        return {
            track
        }
    }
}
