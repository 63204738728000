<template>
    <button
        class="primary-link relative items-center"
        @click="evt => showYears(evt)"
    >
        <span class="link highlighted">
            {{ capitalizeString(category) }}
        </span>
        <SvgRenderer icon="arrow">
            <Arrow :width="20" :height="20" />
        </SvgRenderer>
    </button>
    <ul class="submenu">
        <li>
            <a
                class="link"
                :disabled="haveSameData(paintings, paintingsByCategory)"
                @click="getPaintingsByCategory(category)"
            >
                Tutti
            </a>
        </li>
        <template v-for="(year, idx) in years" :key="`year-${idx}`">
            <li>
                <a
                    class="link"
                    :disabled="
                        clickedYear === year &&
                            haveSameData(paintings, paintingsByYear)
                    "
                    @click="getPaintingsByYear(category, year)"
                >
                    {{ year }}
                </a>
            </li>
        </template>
    </ul>
</template>
<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vue, Options } from 'vue-class-component'
import { useStore } from 'vuex'
import { useGtag } from 'vue-gtag-next'
import Arrow from '../assets/svg/arrow-side.svg'
import PaintingModel from '@/store/models/PaintingModel'
import SvgRenderer from './SvgRenderer.vue'

class Props {
    category!: string
    paintings!: []
}

@Options({
    name: 'YearButtonList',
    props: {
        category: String,
        paintings: {
            type: Array,
            default: []
        }
    },
    components: {
        Arrow,
        SvgRenderer
    }
})
export default class YearBtnList extends Vue.with(Props) {
    store = useStore()
    years: Array<string> = []
    category = ''
    clickedYear = ''
    paintingsByCategory = []
    paintingsByYear = []
    mounted() {
        this.getYears(this.paintings)
        this.paintingsByCategory = this.store.getters.getPaintingsByCategory(
            this.category
        )
    }
    getPaintingsByCategory(category: string) {
        const paintings = this.store.getters.getPaintingsByCategory(category)
        this.trackEvent('Menu laterale', 'Categoria selezionata', category)
        this.store.dispatch('setFilteredArray', paintings)
    }
    getPaintingsByYear(category: string, year: string) {
        this.clickedYear = year
        const paintings = this.store.getters.getPaintingsByCategory(category)
        const array = paintings.filter(
            (painting: PaintingModel) => painting.year === year
        )
        this.trackEvent('Menu laterale', 'Anno selezionato', year)
        this.store.dispatch('setFilteredArray', array)
        this.paintingsByYear = array
    }
    getYears(paintings: any[]) {
        paintings.map(painting => {
            if (!this.years.includes(painting.year)) {
                this.years.push(painting.year)
            }
        })
        return this.years.sort()
    }
    haveSameData(obj1: object, obj2: object) {
        return JSON.stringify(obj1) === JSON.stringify(obj2)
    }
    capitalizeString(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
    showYears(evt: any) {
        const submenu = evt.currentTarget.nextElementSibling
        if (submenu.classList.contains('collapsed')) {
            submenu.classList.remove('collapsed')
        } else {
            submenu.classList.add('collapsed')
        }
    }
    trackEvent(evt, category, label) {
        const { event } = useGtag()
        const track = () => {
            event(evt, {
                eventCategory: category,
                eventLabel: label
            })
        }

        return {
            track
        }
    }
}
</script>
<style lang="scss" scoped>
.primary-link {
    color: $primary;
    display: flex;

    .svg-container {
        margin-top: 0.2rem;
    }

    svg {
        fill: $primary;
    }
}

.submenu {
    overflow: hidden;
    padding-left: 1rem;
    visibility: visible;

    &.collapsed {
        height: 0;
    }

    li {
        margin: 0.5rem 0;
    }
}

a[disabled='true'] {
    background-size: 100% 0.3rem;
    color: lighten($success, 10%);
    cursor: not-allowed;
}
</style>
