<template>
    <ArtGalleryEvent />
</template>
<script lang="ts">
import ArtGalleryEvent from '@/components/ArtGalleryEvent.vue'
import { Options, Vue } from 'vue-class-component'

@Options({
    name: 'Events',
    components: {
        ArtGalleryEvent
    },
    computed: {
        brochureImg() {
            return `${process.env.VUE_APP_IMG_URL}/brochure-1.jpeg`
        }
    }
})
export default class Events extends Vue {}
</script>
