<template>
    <form
        class="w-full max-w-5xl my-16"
        name="contact-form"
        method="post"
        @submit.prevent="checkForm"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
    >
        <input type="hidden" name="form-name" value="contact-form" />
        <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label class="heading form-label" for="grid-first-name">
                    Nome
                </label>
                <input
                    class="form-input"
                    :class="{ showNameError: 'border-red-500' }"
                    id="grid-first-name"
                    type="text"
                    name="name"
                    v-model="form.name"
                    placeholder="Inserisci il tuo nome"
                />
                <p v-show="showNameError" class="text-red-500 text-xl italic">
                    Per favore, inserisci il tuo nome.
                </p>
            </div>
            <div class="w-full md:w-1/2 px-3">
                <label class="heading form-label" for="grid-last-name">
                    Cognome
                </label>
                <input
                    class="form-input"
                    id="grid-last-name"
                    type="text"
                    name="surname"
                    v-model="form.surname"
                    placeholder="Inserisci il tuo cognome"
                />
            </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
                <label class="heading form-label" for="grid-email">
                    Email
                </label>
                <input
                    class="form-input"
                    id="grid-email"
                    :class="{ showEmailError: 'border-red-500' }"
                    type="email"
                    name="email"
                    v-model="form.email"
                    placeholder="Inserisci il tuo indirizzo email"
                />
                <p v-show="showEmailError" class="text-red-500 text-xl italic">
                    Per favore, inserisci un indirizzo email valido.
                </p>
            </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
                <label for="grid-message" class="heading form-label">
                    Messaggio
                </label>
                <textarea
                    id="grid-message"
                    :class="{ showMessageError: 'border-red-500' }"
                    class="form-input"
                    rows="5"
                    name="message"
                    v-model="form.message"
                    placeholder="Inserisci il tuo messaggio"
                ></textarea>
                <p
                    v-show="showMessageError"
                    class="text-red-500 text-xl italic"
                >
                    Per favore, inserisci un messaggio.
                </p>
            </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
                <button
                    :disabled="isSubmitted"
                    type="submit"
                    class="btn btn-primary"
                >
                    Invia
                </button>
            </div>
        </div>
    </form>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'

@Options({
    name: 'ContactForm'
})
export default class ContactForm extends Vue {
    form = {
        name: '',
        surname: '',
        message: '',
        email: ''
    }
    isSubmitted = false
    showNameError = false
    showEmailError = false
    showMessageError = false
    isEmailValid() {
        const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        return this.form.email && emailFormat.test(this.form.email)
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    encode(data: any) {
        return Object.keys(data)
            .map(
                key =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(
                        data[key]
                    )}`
            )
            .join('&')
    }
    checkForm() {
        const emailIsValid = this.isEmailValid()
        this.showNameError = !this.form.name
        this.showEmailError = !emailIsValid
        this.showMessageError = !this.form.message

        if (this.form.name && emailIsValid && this.form.message) {
            this.isSubmitted = true
            fetch('/contacts', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: this.encode({
                    'form-name': 'contact-form',
                    subject: 'Nuovo messaggio da gcolomboart.com',
                    ...this.form
                })
            })
                .then(() => {
                    this.submitForm('success')
                })
                .catch(() => {
                    this.submitForm('danger')
                })
        }
    }
    submitForm(evt: string) {
        this.$emit('setSubmissionType', evt)
        this.resetSubmission()
    }
    resetSubmission() {
        setTimeout(() => {
            this.isSubmitted = false
            this.$emit('setSubmissionType', '')
            this.form = {
                name: '',
                surname: '',
                message: '',
                email: ''
            }
        }, 5000)
    }
}
</script>
<style lang="scss" scoped>
.btn {
    @apply font-bold py-4 px-8 rounded;

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.6;
    }

    &-primary {
        @apply text-white;
        background: $success;

        &:hover {
            background: darken($success, 10%);
        }
    }
}

.form {
    &-label {
        @apply block uppercase tracking-wide text-xl mb-2;
    }
    &-input {
        @apply appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white;
    }
}
</style>
