
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { mapGetters, useStore } from 'vuex'
import { Options, Vue } from 'vue-class-component'
import { useGtag } from 'vue-gtag-next'
import ImageModal from './ImageModal.vue'
import PaintingImage from './PaintingImage.vue'
import PaintingDescription from './PaintingDescription.vue'
import PaintingsSlider from './PaintingsSlider.vue'

class Props {
    paintingId!: string
}

interface Image {
    url: string
}

interface Painting {
    id: string
    title: string
    size: string
    image: Image
    description: string
    category: string
}

@Options({
    name: 'PaintingDetails',
    props: {
        paintingId: String
    },
    components: {
        ImageModal,
        PaintingImage,
        PaintingDescription,
        PaintingsSlider
    },
    computed: {
        ...mapGetters({
            allPaintings: 'getAllPaintings',
            filteredPaintings: 'getFilteredArray'
        })
    }
})
export default class PaintingDetails extends Vue.with(Props) {
    allPaintings!: []
    store = useStore()
    isModalOn = false
    imgModal: object = {}
    painting: Painting = {
        id: '0',
        title: '',
        description: '',
        size: '',
        image: {
            url: ''
        },
        category: ''
    }
    filteredPaintings!: []
    async mounted() {
        if (this.filteredPaintings.length === 0) {
            await this.store.dispatch('setPaintingsArray')
            this.painting = this.filterPainting(this.allPaintings)
            this.trackEvent(
                'Pagina opera diretta',
                'Opera selezionata',
                this.painting.title
            )
        } else {
            this.trackEvent(
                'Slider opere',
                'Opera selezionata',
                this.painting.title
            )
        }
    }
    filterPainting(array: any) {
        return array.filter(({ id }: any) => id === this.paintingId)[0]
    }
    closeModal() {
        this.isModalOn = false
    }
    openImageModal(painting: object) {
        this.imgModal = painting
        this.isModalOn = true
    }
    trackEvent(evt, category, label) {
        const { event } = useGtag()
        const track = () => {
            event(evt, {
                eventCategory: category,
                eventLabel: label
            })
        }

        return {
            track
        }
    }
}
