
import { Vue, Options } from 'vue-class-component'

@Options({
    name: 'ContactForm'
})
export default class ContactForm extends Vue {
    form = {
        name: '',
        surname: '',
        message: '',
        email: ''
    }
    isSubmitted = false
    showNameError = false
    showEmailError = false
    showMessageError = false
    isEmailValid() {
        const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        return this.form.email && emailFormat.test(this.form.email)
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    encode(data: any) {
        return Object.keys(data)
            .map(
                key =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(
                        data[key]
                    )}`
            )
            .join('&')
    }
    checkForm() {
        const emailIsValid = this.isEmailValid()
        this.showNameError = !this.form.name
        this.showEmailError = !emailIsValid
        this.showMessageError = !this.form.message

        if (this.form.name && emailIsValid && this.form.message) {
            this.isSubmitted = true
            fetch('/contacts', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: this.encode({
                    'form-name': 'contact-form',
                    subject: 'Nuovo messaggio da gcolomboart.com',
                    ...this.form
                })
            })
                .then(() => {
                    this.submitForm('success')
                })
                .catch(() => {
                    this.submitForm('danger')
                })
        }
    }
    submitForm(evt: string) {
        this.$emit('setSubmissionType', evt)
        this.resetSubmission()
    }
    resetSubmission() {
        setTimeout(() => {
            this.isSubmitted = false
            this.$emit('setSubmissionType', '')
            this.form = {
                name: '',
                surname: '',
                message: '',
                email: ''
            }
        }, 5000)
    }
}
