<template>
    <div class="contacts">
        <h2 class="text-4xl mb-16">
            Lasciaci un messaggio
        </h2>
        <ContactForm @set-submission-type="setSubmissionType" />
        <div>
            <p class="flex items-center">
                Altrimenti puoi contattarci direttamente al numbero
                <a
                    href="tel:+393516263217"
                    aria-label="phone number"
                    rel="noopener noreferrer"
                    target="_blank"
                    class="link-with-icon"
                >
                    <div class="flex items-center">
                        <div class="icon-wrapper">
                            <SvgRenderer icon="phone">
                                <Phone />
                            </SvgRenderer>
                        </div>
                        &nbsp;351 626 3217
                    </div>
                </a>
            </p>
        </div>
        <NotificationFlag :type="submissionType" />
    </div>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { mapGetters, useStore } from 'vuex'
import ContactForm from '@/components/ContactForm.vue'
import NotificationFlag from '@/components/NotificationFlag.vue'
import SvgRenderer from '@/components/SvgRenderer.vue'
import Phone from '@/assets/svg/phone.svg'

@Options({
    name: 'Contacts',
    computed: {
        ...mapGetters(['isMobileMenuOpen'])
    },
    components: {
        ContactForm,
        NotificationFlag,
        Phone,
        SvgRenderer
    }
})
export default class Contacts extends Vue {
    store = useStore()
    submissionType = ''
    isMobileMenuOpen!: boolean
    mounted() {
        if (this.isMobileMenuOpen) {
            this.store.dispatch('toggleMenuMobile')
        }
    }
    setSubmissionType(value: string) {
        this.submissionType = value
    }
}
</script>
<style lang="scss" scoped>
.contacts {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
}
</style>
