<template>
    <transition name="fade">
        <div
            v-show="type"
            :class="[
                { 'bg-success': type === 'success' },
                { 'bg-danger': type === 'danger' },
                'flag p-12 rounded'
            ]"
        >
            <p class="text-2xl">
                {{ message }}
            </p>
        </div>
    </transition>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'

class Props {
    type!: string
}

@Options({
    name: 'NotificationFlag',
    props: {
        type: String
    },
    computed: {
        message() {
            return this.type === 'success'
                ? 'Email inviata con successo'
                : "C'è stato un errore nell' invio della email. Prova di nuovo."
        }
    }
})
export default class NotificationFlag extends Vue.with(Props) {}
</script>
<style lang="scss" scoped>
.bg {
    &-success {
        background-color: $success;
    }
    &-danger {
        background-color: $danger;
    }
}

.flag {
    color: $lightShades;
    position: fixed;
    right: 2rem;
    top: 10rem;
}
</style>
