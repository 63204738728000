
import { Vue, Options } from 'vue-class-component'
import { mapGetters } from 'vuex'
import YearBtnList from './YearBtnList.vue'
import LinkToCategory from './LinkToCategory.vue'

@Options({
    name: 'SideMenu',
    props: {
        selectedCategory: String,
        filteredPaintings: {
            type: Array,
            default: []
        }
    },
    components: {
        LinkToCategory,
        YearBtnList
    },
    computed: {
        ...mapGetters({
            categoriesName: 'getAllCategoriesName'
        })
    }
})
export default class SideMenu extends Vue {
    capitalizeString(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
    stringify(string: string) {
        return string.replace(/([A-Z])/g, ' $1')
    }
}
