
import { Options, Vue } from 'vue-class-component'

@Options({
    name: 'PaintingDescription',
    props: {
        title: String,
        description: String,
        category: String,
        size: String
    }
})
export default class PaintingDescription extends Vue {}
