<template>
    <div class="card-text">
        <div class="flex flex-col justify-start md:justify-center h-full">
            <h3 class="text-5xl mb-4">
                {{ title }}
            </h3>
            <p class="mb-4">Dimensioni: {{ size }}</p>
            <p class="mb-4">
                {{ category.toUpperCase() }}
            </p>
            <p v-if="description" class="mb-4">
                {{ description }}
            </p>
            <div>
                Se interessati a saperne di piú riguardo questa opera scriveteci
                a
                <a class="link highlighted" href="mailto:gcolomboart@gmail.com">
                    gcolomboart@gmail.com
                </a>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
    name: 'PaintingDescription',
    props: {
        title: String,
        description: String,
        category: String,
        size: String
    }
})
export default class PaintingDescription extends Vue {}
</script>
<style lang="scss" scoped>
.card-text {
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
</style>
