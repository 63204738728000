<template>
    <div class="relative h-20 my-4 w-full md:absolute z-10">
        <button
            :disabled="indexPainting === 0"
            class="absolute w-20 h-20 left-0 slide-prev transform rotate-180"
            @click="showPrev"
        >
            <SvgRenderer icon="arrow">
                <Arrow />
            </SvgRenderer>
        </button>

        <button
            :disabled="indexPainting === filteredPaintings.length - 1"
            class="absolute w-20 h-20 right-0"
            @click="showNext"
        >
            <SvgRenderer icon="arrow">
                <Arrow />
            </SvgRenderer>
        </button>
    </div>
</template>
<script lang="ts">
import { mapGetters } from 'vuex'
import { Options, Vue } from 'vue-class-component'
import Arrow from '../assets/svg/arrow-side.svg'
import SvgRenderer from './SvgRenderer.vue'

class Props {
    index!: number
}

@Options({
    name: 'PaintingsSlider',
    props: {
        index: Number
    },
    components: {
        Arrow,
        SvgRenderer
    },
    computed: {
        ...mapGetters({
            filteredPaintings: 'getFilteredArray'
        })
    },
    watch: {
        index(newVal) {
            this.indexPainting = newVal
        }
    }
})
export default class SliderController extends Vue.with(Props) {
    indexPainting = this.index
    filteredPaintings!: []
    showNext() {
        if (this.indexPainting < this.filteredPaintings.length - 1) {
            this.indexPainting += 1
            this.emitEvent('show-next-slide', this.indexPainting, 'next')
        }
    }
    showPrev() {
        if (this.indexPainting > 0) {
            this.indexPainting -= 1
            this.emitEvent('show-prev-slide', this.indexPainting, 'back')
        }
    }
    emitEvent(evt: string, newIndex: number, direction: string) {
        this.$emit(evt, {
            newIndex,
            direction
        })
    }
}
</script>
<style lang="scss" scoped>
.slide-prev {
    div {
        transform: translateX(-50%) translateY(-50%) rotate(180deg);
    }
}

button {
    fill: $success;

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.6;
    }
}
</style>
