
import { mapGetters } from 'vuex'
import { Options, Vue } from 'vue-class-component'
import Arrow from '../assets/svg/arrow-side.svg'
import SvgRenderer from './SvgRenderer.vue'

class Props {
    index!: number
}

@Options({
    name: 'PaintingsSlider',
    props: {
        index: Number
    },
    components: {
        Arrow,
        SvgRenderer
    },
    computed: {
        ...mapGetters({
            filteredPaintings: 'getFilteredArray'
        })
    },
    watch: {
        index(newVal) {
            this.indexPainting = newVal
        }
    }
})
export default class SliderController extends Vue.with(Props) {
    indexPainting = this.index
    filteredPaintings!: []
    showNext() {
        if (this.indexPainting < this.filteredPaintings.length - 1) {
            this.indexPainting += 1
            this.emitEvent('show-next-slide', this.indexPainting, 'next')
        }
    }
    showPrev() {
        if (this.indexPainting > 0) {
            this.indexPainting -= 1
            this.emitEvent('show-prev-slide', this.indexPainting, 'back')
        }
    }
    emitEvent(evt: string, newIndex: number, direction: string) {
        this.$emit(evt, {
            newIndex,
            direction
        })
    }
}
