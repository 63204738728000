
import { Vue, Options } from 'vue-class-component'
import { mapGetters, useStore } from 'vuex'
@Options({
    name: 'About',
    computed: {
        ...mapGetters(['isMobileMenuOpen'])
    }
})
export default class About extends Vue {
    store = useStore()
    isMobileMenuOpen!: boolean
    mounted() {
        if (this.isMobileMenuOpen) {
            this.store.dispatch('toggleMenuMobile')
        }
    }
}
