<template>
    <div
        class="modal h-full fixed w-full flex items-center justify-center z-50 top-0 left-0"
    >
        <div class="background h-full absolute w-full"></div>
        <SvgRenderer icon="close" @click="closeModal">
            <Close :width="50" :height="50" />
        </SvgRenderer>
        <div class="img-wrapper">
            <PaintingImage
                :paintingId="id"
                :category="category"
                :title="title"
            />
        </div>
    </div>
</template>
<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Options, Vue } from 'vue-class-component'
import Close from '../assets/svg/close.svg'
import PaintingImage from './PaintingImage.vue'
import SvgRenderer from './SvgRenderer.vue'

class Props {
    img!: object
}

@Options({
    name: 'ImageModal',
    props: {
        img: Object
    },
    components: {
        Close,
        PaintingImage,
        SvgRenderer
    },
    computed: {
        category() {
            return this.img.category
        },
        id() {
            return this.img.id
        },
        title() {
            return this.img.title
        }
    }
})
export default class ImageModal extends Vue.with(Props) {
    closeModal() {
        this.$emit('closeModal')
    }
}
</script>
<style scoped lang="scss">
.img-wrapper {
    height: calc(100% - 8rem);

    .card {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: auto;
    }
}

.background {
    background-color: $black;
    left: 0;
    opacity: 0.7;
    top: 0;
    z-index: -1;
}

.close {
    color: $white;
    position: absolute;
    top: 1rem;
    right: 1rem;
}
</style>
