<template>
    <div class="flex mx-auto">
        <SideMenu
            v-if="selectedCategory.length > 0 && filteredPaintings.length > 0"
            :selectedCategory="selectedCategory"
            :filteredPaintings="filteredPaintings"
        />
        <div class="row" v-if="chunkedArray.length > 0">
            <div
                class="col"
                :key="`painting-${index}`"
                v-for="(paintings, index) in chunkedArray"
            >
                <router-link
                    class="grid-el"
                    :to="`/painting/${artwork.id}`"
                    :key="artwork.id"
                    v-for="artwork in paintings"
                >
                    <PaintingImage
                        v-if="artwork"
                        :paintingId="artwork.id"
                        :category="artwork.category"
                        :title="artwork.title"
                    />
                    <div class="overlay">
                        <div>
                            <h3>{{ artwork.title }}</h3>
                            <p>Anno: {{ artwork.year }}</p>
                        </div>
                        <SvgRenderer icon="expand">
                            <Expand />
                        </SvgRenderer>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Options, Vue } from 'vue-class-component'
import { mapGetters, useStore } from 'vuex'
import { useGtag } from 'vue-gtag-next'
import Expand from '../assets/svg/expand.svg'
import SvgRenderer from '@/components/SvgRenderer.vue'
import PaintingImage from '@/components/PaintingImage.vue'
import SideMenu from '@/components/SideMenu.vue'

@Options({
    name: 'Gallery',
    components: {
        Expand,
        PaintingImage,
        SideMenu,
        SvgRenderer
    },
    computed: {
        ...mapGetters({
            allPaintings: 'getAllPaintings',
            categoriesName: 'getAllCategoriesName',
            isMobileMenuOpen: 'isMobileMenuOpen',
            filteredPaintings: 'getFilteredArray'
        })
    },
    watch: {
        '$route.params.category': {
            handler: function(category, categoryOld) {
                if (category && categoryOld !== category) {
                    this.setArray(category)
                    this.trackCategory(category)
                    if (this.isMobileMenuOpen) {
                        this.store.dispatch('toggleMenuMobile')
                    }
                }
            },
            deep: true,
            immediate: true
        },
        filteredPaintings(newArray, oldArray) {
            if (newArray !== oldArray) {
                this.chunkedArray = this.createGroups(newArray, this.numChunks)
            }
        }
    }
})
export default class Gallery extends Vue {
    allPaintings!: []
    isMobileMenuOpen!: boolean
    store = useStore()
    selectedCategory = ''
    categoriesName!: Array<string>
    filteredPaintings!: []
    numChunks = 3
    chunkedArray: Array<Array<object>> = []
    async created() {
        await this.store.dispatch('setPaintingsArray')
        this.chunkedArray = this.createGroups(
            this.filteredPaintings,
            this.numChunks
        )
    }
    mounted() {
        this.setArray(this.$route.params.category)
        if (this.isMobileMenuOpen) {
            this.store.dispatch('toggleMenuMobile')
        }
    }
    setArray(category: any) {
        this.selectedCategory = this.removeDashCategory(category)
        const filteredArray =
            this.selectedCategory === 'all'
                ? this.allPaintings
                : this.store.getters.getPaintingsByCategory(
                      this.selectedCategory
                  )
        this.store.dispatch('setFilteredArray', filteredArray)
    }
    removeDashCategory(string: string) {
        return string.replace(/-/g, ' ')
    }
    capitalise(string: string) {
        return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
    }
    createGroups(arr, numGroups) {
        const perGroup = Math.ceil(arr.length / numGroups)
        return new Array(numGroups)
            .fill('')
            .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup))
    }
    trackCategory(category) {
        const { event } = useGtag()
        const track = () => {
            event('Pagina iniziale', {
                eventCategory: 'Categoria Selezionata',
                eventLabel: category
            })
        }

        return {
            track
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    @include responsive(md) {
        margin: 0 auto;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
}

.col {
    flex: 100%;
    max-width: 100%;
    padding: 0 4px;

    img {
        margin-top: 8px;
        vertical-align: middle;
        width: 100%;
    }

    @include responsive(md) {
        flex: calc(100% / 3);
        max-width: calc(100% / 3);
    }
}

.grid-el {
    margin: 0 auto 1.5rem;
    position: relative;
    width: 100%;

    .overlay {
        align-items: flex-end;
        background-color: darken($primary, 5%);
        color: $white;
        display: flex;
        height: 100%;
        opacity: 0;
        padding: 2rem;
        position: absolute;
        top: 0;
        transition: opacity 0.3s ease;
        width: 100%;

        p,
        h3 {
            margin: 0.5rem 0;
        }
    }

    &:hover {
        .overlay {
            opacity: 0.8;
        }
    }
}
</style>
