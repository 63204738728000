
import { Options, Vue } from 'vue-class-component'

class Props {
    type!: string
}

@Options({
    name: 'NotificationFlag',
    props: {
        type: String
    },
    computed: {
        message() {
            return this.type === 'success'
                ? 'Email inviata con successo'
                : "C'è stato un errore nell' invio della email. Prova di nuovo."
        }
    }
})
export default class NotificationFlag extends Vue.with(Props) {}
