<template>
    <div class="md:flex items-center block-center">
        <PaintingsSlider
            v-if="filteredPaintings.length > 0"
            :paintingId="paintingId"
        />
        <template v-else>
            <div
                class="relative md:inline-block h-2/4 md:h-full md:mx-8 md:align-middle md:w-3/5"
            >
                <div class="md:flex items-center h-full">
                    <div
                        class="painting relative my-0 mx-auto md:w-9/12 h-full"
                    >
                        <PaintingImage
                            v-if="painting"
                            @click="openImageModal(painting)"
                            :paintingId="painting.id"
                            :category="painting.category"
                            :title="painting.title"
                        />
                    </div>
                </div>
            </div>
            <div
                class="relative md:inline-block md:m-8 align-middle md:w-1/4 h-2/4 md:h-full mt-32 md:mt-0 text-center md:text-left"
            >
                <PaintingDescription
                    :title="painting.title"
                    :description="painting.description"
                    :category="painting.category"
                    :size="painting.size"
                />
            </div>

            <ImageModal
                v-if="isModalOn && imgModal"
                :img="imgModal"
                @close-modal="closeModal"
            />
        </template>
    </div>
</template>
<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { mapGetters, useStore } from 'vuex'
import { Options, Vue } from 'vue-class-component'
import { useGtag } from 'vue-gtag-next'
import ImageModal from './ImageModal.vue'
import PaintingImage from './PaintingImage.vue'
import PaintingDescription from './PaintingDescription.vue'
import PaintingsSlider from './PaintingsSlider.vue'

class Props {
    paintingId!: string
}

interface Image {
    url: string
}

interface Painting {
    id: string
    title: string
    size: string
    image: Image
    description: string
    category: string
}

@Options({
    name: 'PaintingDetails',
    props: {
        paintingId: String
    },
    components: {
        ImageModal,
        PaintingImage,
        PaintingDescription,
        PaintingsSlider
    },
    computed: {
        ...mapGetters({
            allPaintings: 'getAllPaintings',
            filteredPaintings: 'getFilteredArray'
        })
    }
})
export default class PaintingDetails extends Vue.with(Props) {
    allPaintings!: []
    store = useStore()
    isModalOn = false
    imgModal: object = {}
    painting: Painting = {
        id: '0',
        title: '',
        description: '',
        size: '',
        image: {
            url: ''
        },
        category: ''
    }
    filteredPaintings!: []
    async mounted() {
        if (this.filteredPaintings.length === 0) {
            await this.store.dispatch('setPaintingsArray')
            this.painting = this.filterPainting(this.allPaintings)
            this.trackEvent(
                'Pagina opera diretta',
                'Opera selezionata',
                this.painting.title
            )
        } else {
            this.trackEvent(
                'Slider opere',
                'Opera selezionata',
                this.painting.title
            )
        }
    }
    filterPainting(array: any) {
        return array.filter(({ id }: any) => id === this.paintingId)[0]
    }
    closeModal() {
        this.isModalOn = false
    }
    openImageModal(painting: object) {
        this.imgModal = painting
        this.isModalOn = true
    }
    trackEvent(evt, category, label) {
        const { event } = useGtag()
        const track = () => {
            event(evt, {
                eventCategory: category,
                eventLabel: label
            })
        }

        return {
            track
        }
    }
}
</script>
<style lang="scss" scoped>
.block-center {
    height: 90%;

    @include responsive(md) {
        padding: 3rem 0;
        overflow: hidden;
    }
}

.card {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    img {
        left: 50%;
        margin: auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        width: 100%;
    }
}
</style>
