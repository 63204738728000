<template>
    <div class="hidden md:flex flex-col mr-8 my-8 min-w-max">
        <ul class="inline-block my-2">
            <template
                v-for="(category, index) in categoriesName"
                :key="`category-${index}`"
            >
                <li class="block">
                    <LinkToCategory
                        v-if="selectedCategory !== category"
                        :category="stringify(category)"
                    />
                    <template v-else>
                        <YearBtnList
                            :category="stringify(category)"
                            :paintings="filteredPaintings"
                        />
                    </template>
                </li>
            </template>
        </ul>
    </div>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { mapGetters } from 'vuex'
import YearBtnList from './YearBtnList.vue'
import LinkToCategory from './LinkToCategory.vue'

@Options({
    name: 'SideMenu',
    props: {
        selectedCategory: String,
        filteredPaintings: {
            type: Array,
            default: []
        }
    },
    components: {
        LinkToCategory,
        YearBtnList
    },
    computed: {
        ...mapGetters({
            categoriesName: 'getAllCategoriesName'
        })
    }
})
export default class SideMenu extends Vue {
    capitalizeString(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
    stringify(string: string) {
        return string.replace(/([A-Z])/g, ' $1')
    }
}
</script>
